import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from '@emotion/styled'

import ProductListingHeader from './ProductListingHeader'
import ProductListingItem from './ProductListingItem'

import { breakpoints, spacing } from '../../utils/styles'

const ProductListingContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacing.lg}px;

  @media (min-width: ${breakpoints.desktop}px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${spacing['2xl']}px;
  }
`

const ProductListing = () => (
  <StaticQuery
    query={graphql`
      query ProductListingQuery {
        products: allProduct(
          sort: { fields: [publishedAt], order: DESC }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              title
              description
              variants {
                name
                currency
                amount
              }
              images {
                alt
                file {
                  id
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED, 
                      height: 910, 
                      width: 910, 
                      formats: [AUTO, WEBP, AVIF])
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ products }) => (
      <>
        <ProductListingHeader />
        <ProductListingContainer>
          {products.edges.map(({ node: product }) => (
            <ProductListingItem key={product.id} product={product} />
          ))}
        </ProductListingContainer>
      </>
    )}
  />
)

export default ProductListing
